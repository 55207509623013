import {SourceType} from '@matchsource/models/source';
import {SearchStateModel} from '@matchsource/models/search-states';

export enum SearchStatusRoutes {
  Search = 'search',
  SearchStatus = 'search.status',
  SearchStatusRunning = 'search.status.running',
  SearchStatusUnviewed = 'search.status.unviewed',
}

export interface Patient {
  firstName: string;
  lastName: string;
  patientIdFormatted: string;
  coreSubmissionFailed: boolean;
  id: MsApp.Guid;
  removed?: boolean;
}

export interface SourceSearch<T extends SourceType> {
  sourceType: T;
  status: string;
  searchCompleteDate: MsApp.DateString;
}

export interface Search {
  completeDateFormatted: string;
  id: string;
  phenotypeId: number;
  selected: boolean;
  inProgress: boolean;
  statusDescription: string;
  patientId: MsApp.Guid;
  hasDonorSearch: boolean;
  hasCordSearch: boolean;
  hasBdpSearch: boolean;
  cordSearch: SourceSearch<'CORD'>;
  donorSearch: SourceSearch<'DONOR'>;
  bdpSearch: SourceSearch<'BDP'>;
  isPartiallyAccessible: boolean;
}

export interface SearchGroup {
  length: number;
  patientId: MsApp.Guid;
  searches: SearchStateModel[];
  allSearchesCompleted: boolean;
}

export interface PatientSearchGroup {
  group: SearchGroup;
  patient: Patient;
}

export type SearchGroups = SearchGroup[];
export type UnviewedSearches = PatientSearchGroup[];
export type RunningSearches = UnviewedSearches;

export interface SearchCompletedEventParams {
  searchGuid: MsApp.Guid;
  patientId: MsApp.Guid;
  autoRedirectToResults: boolean;
  sourceType: 'cord' | 'donor' | 'BDP';
  isOnlyBdp?: boolean;
}
